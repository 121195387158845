import React from "react";
import PropTypes from "prop-types";

function getColor(severity) {
  switch (severity) {
    case 1:
      return "green";
    case 2:
      return "orange";
    case 3:
      return "red";
    case 0:
    default:
      return "white";
  }
}
const Message = (props) => {
  const color = getColor(props.severity);
  return (
    <span style={{ color: color, marginBottom: "1em" }}>{props.text}</span>
  );
};

Message.propTypes = {
  text: PropTypes.string,
  severity: PropTypes.number,
};

export default Message;
