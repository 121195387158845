import React from "react";
import styled from "styled-components";
import { Route, NavLink, withRouter, Redirect, Switch } from "react-router-dom";

import MessagesManual from "../containers/MessagesManual";
import MessagesAuto from "../containers/MessagesAuto";
import MessagesAdmin from "../containers/MessagesAdmin";
import MessagesBackend from "../containers/MessagesBackend";
import withAuthorization from "../components/Session/withAuthorization";

const activeStyle = {
  color: "teal",
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LIST_BG_COLOR = "#2f2f2f";
const LinksList = styled.ul`
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  background: ${LIST_BG_COLOR};
`;
const LinksListItem = styled.li`
  margin-right: 20px;
`;

const Content = styled.div`
  padding: 10px;
  flex: 1;
  height: 100%;
`;

const ROLES_TO_SEND = ["admin", "client", "whitelabel"];
const ALERTS_TO_SEND = ["invStopAlert"];

function checkUserAlertsMedium(user, alertType, alertMedium) {
  return (
    user &&
    user.alerts &&
    user.alerts.toSend &&
    user.alerts.toSend[alertType] &&
    user.alerts.toSend[alertType].isActive &&
    user.alerts.toSend[alertType].methods &&
    user.alerts.toSend[alertType].methods[alertMedium]
  );
}

class MessagesPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  matchOrgsToUsers = () => {
    const { systemsData, orgsData, usersData } = this.props;
    if (!systemsData || !orgsData || !usersData) {
      return {};
    }
    const sysMeta = Object.entries(usersData).reduce(
      (sysMatch, [phone, user]) => {
        // filter out users that are not meant to get messages
        if (ROLES_TO_SEND.includes(user.role)) {
          const orgId = user.organization;
          orgsData[orgId].systemIds.forEach((sysId) => {
            if (!sysMatch[sysId]) {
              sysMatch[sysId] = {
                label: user.label[0],
                orgs: systemsData[sysId].orgs,
                phones: [],
                emails: [],
              };
            }
            if (
              !sysMatch[sysId].phones.includes(phone) &&
              ALERTS_TO_SEND.some((alertType) =>
                checkUserAlertsMedium(user, alertType, "sms")
              )
            ) {
              sysMatch[sysId].phones.push(phone);
            }
            if (
              user.email &&
              !sysMatch[sysId].emails.includes(user.email) &&
              ALERTS_TO_SEND.some((alertType) =>
                checkUserAlertsMedium(user, alertType, "email")
              )
            ) {
              sysMatch[sysId].emails.push(user.email);
            }
            if (sysMatch[sysId].label !== user.label[0]) {
              sysMatch[sysId].label = "";
            }
          });
        }
        return sysMatch;
      },
      {}
    );
    return {
      emptySys: { label: "", orgs: [], phones: [], emails: [] },
      sysMeta,
    };
  };

  render() {
    const { match, systemsData, loadError, orgsData, usersData } = this.props;

    // remove references, filter out frozen systems, divide into test, non-test and frozen systems
    const [testSystems, noRefSystems, frozenSystems] = !systemsData
      ? [null, null, null]
      : Object.keys(systemsData).reduce(
          ([test, noRef, frozenSys], id) => {
            const sysData = systemsData[id];
            if (!sysData.is_reference) {
              if (sysData.frozen) {
                frozenSys[id] = sysData;
              } else if (sysData.region !== "il.test") {
                noRef[id] = sysData;
              } else {
                test[id] = sysData;
              }
            }
            return [test, noRef, frozenSys];
          },
          [{}, {}, {}]
        );

    const { emptySys, sysMeta } = this.matchOrgsToUsers();

    return (
      <Wrapper>
        <LinksList>
          <LinksListItem key="manual">
            <NavLink to={`${match.url}/manual`} activeStyle={activeStyle}>
              Manual
            </NavLink>
          </LinksListItem>
          <LinksListItem>
            <NavLink to={`${match.url}/auto`} activeStyle={activeStyle}>
              Mlog
            </NavLink>
          </LinksListItem>
          <LinksListItem>
            <NavLink to={`${match.url}/admin`} activeStyle={activeStyle}>
              Administrative
            </NavLink>
          </LinksListItem>
          <LinksListItem>
            <NavLink to={`${match.url}/new-auto`} activeStyle={activeStyle}>
              DBLog
            </NavLink>
          </LinksListItem>
        </LinksList>
        <Content>
          <Switch>
            <Route
              path={`${match.path}/manual`}
              render={() => (
                <MessagesManual
                  noRefSystems={noRefSystems}
                  testSystems={testSystems}
                  frozenSystems={frozenSystems}
                  sysMatch={{ "": emptySys, ...sysMeta }}
                />
              )}
            />
            <Route
              path={`${match.path}/auto`}
              render={() => (
                <MessagesAuto
                  noRefSystems={noRefSystems}
                  testSystems={testSystems}
                  orgsData={orgsData}
                  usersData={usersData}
                  loadError={loadError}
                />
              )}
            />
            <Route
              path={`${match.path}/new-auto`}
              render={() => (
                <MessagesBackend
                  noRefSystems={noRefSystems}
                  testSystems={testSystems}
                  orgsData={orgsData}
                  usersData={usersData}
                  loadError={loadError}
                />
              )}
            />
            <Route
              path={`${match.path}/admin`}
              render={() => (
                <MessagesAdmin
                  noRefSystems={noRefSystems}
                  testSystems={testSystems}
                  frozenSystems={frozenSystems}
                  usersData={usersData}
                  orgsData={orgsData}
                  sysMatch={{ "": emptySys, ...sysMeta }}
                />
              )}
            />
            <Redirect
              from={`${match.path}`}
              to={`${match.url}/manual`}
              exact={true}
              strict={true}
            />
          </Switch>
        </Content>
      </Wrapper>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(MessagesPage));
