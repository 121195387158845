import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import messageGenerator from "@soltell/soltell-message-generator";

import * as sms from "../../services/Sms";
import { loadFromSheetsApi } from "../../services/Spreadsheet";
import IssueItem from "../IssueItem";
import MessageLogItem from "../MessagesManual/MessageLogItem";
import LocalStorageKeys from "../../localStorageKeys";
import OperationalTabModel from "../../models/OperationalTabModel";

import { sendData } from "../../services/sendData";

const { fixedTs, extractIssues } = messageGenerator;

const ISSUES_DATA = Object.fromEntries(
  Object.entries(messageGenerator.getIssuesData()).map(([key, val]) => {
    const sortedVal = [["ok", { msg: "", severity: 0 }], ...Object.entries(val)]
      .map(([status, data]) => ({ status, data }))
      .sort(({ data: d1 }, { data: d2 }) => d1.severity - d2.severity);
    return [key, sortedVal];
  })
);

const TRANSLATOR_VERSION = messageGenerator.version();

// deprecated
// const OPERATIONAL_TAB = 'daily';
const SOURCE_TAB = "source";

const BASE_SEVERITY_THRESHOLD = 1;
const WARNING_SEVERITY_THRESHOLD = 2;
const ALERT_SEVERITY_THRESHOLD = 3;

const MINUTES_BEFORE_STALE_DATA = 120;

const LIST_SEPERATOR = ",";
const DEFAULT_LNG = "he";

const ROLES_TO_SEND = ["admin", "client", "whitelabel"];
const ALERTS_TO_SEND = [
  // "channelStopAlert",
  // "commStopAlert",
  "invStopAlert",
  // "subchannelStopAlert",
];

const ISSUES_TO_FILTER = ["f-shadow", "f-mcm", "ef-webbox", "if-invdeg-n"];

const FILTER_FROM_GSHEET = ["f-erange", "f-prange", "regionrating"];

const Section = styled.div`
  padding: 10px 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;

const getCleanIssueState = (
  id,
  issue,
  label = "",
  phonesString = "",
  emailsString = ""
) => {
  return {
    id: id,
    shouldSend: false,
    label: label,
    phonesString: phonesString,
    emailsString: emailsString,
    lng: DEFAULT_LNG,
    ...issue,
  };
};

const LOG_STATUS = {
  pending: "pending",
  sending: "sending",
  success: "success",
  failure: "failure",
};

// constructor for object representing a message log
function MessageLog(
  sysId,
  recipient,
  emails,
  status,
  body,
  sender,
  msgCode,
  error = null
) {
  this.recipientNumber = [...recipient]; // recipient number
  this.emails = [...emails]; // recipient emails
  this.singlePhoneError = recipient.map(() => null); // errors for individual phone number
  this.singlePhoneStatus = recipient.map(() => status); // status for individual phone number
  this.messageId = recipient.map(() => ""); // aws message ids
  this.sysId = sysId || "";
  this.type = sms.getMessageTypes()[0]; // type of the message
  this.status = status; // log status
  this.body = body; // message body
  this.sender = sender; // sender alias
  this.msgCode = msgCode; // code of the automatic message
  this.error = error; // error if exists
  this.lastEdit = moment();
}

function validateLocalStorage(localData) {
  if (
    !localData ||
    !localData.data ||
    !localData.updateTimestamp ||
    !moment(localData.updateTimestamp).isValid()
  ) {
    console.warn(`missing/corrupted data shape`);
    return false;
  }
  if (
    moment().diff(localData.updateTimestamp, "minutes") >
    MINUTES_BEFORE_STALE_DATA
  ) {
    console.warn(`data too stale, over ${MINUTES_BEFORE_STALE_DATA} minutes`);
    return false;
  }
  console.log(`local storage data seems OK`);
  return true;
}

class AutoMessagesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      dataUpdateMoment: null,
      issues: null,
      sendQueue: [],
      sendPromiseIdx: null,
      isPushingInsights: false,
      insightsPushStatus: "idle",
      invalidSystemDate: false,
      invalidSystemConfiguration: false,
      sendInsights: true,
    };
  }

  // cleans local storage from data
  cleanLocalStorageData = () => {
    localStorage.removeItem(LocalStorageKeys.mlogData);
    this.setState(() => {
      if (!this._isMounted) {
        return;
      }
      return {
        data: null,
        dataUpdateMoment: null,
        issues: null,
        invalidSystemDate: false,
      };
    });
  };

  // reload operational data form mlog sheet, and update local storage
  reloadOperationalData = (sysIds) => {
    loadFromSheetsApi(sysIds, SOURCE_TAB).then((mlogData) => {
      if (!this._isMounted) {
        return;
      }
      const updateTime = moment();
      const invalidDate = Object.values(mlogData).some(
        (datum) => !moment(datum.date, "DD/MM/YYYY").isValid()
      );
      const filteredData = Object.entries(mlogData).reduce(
        (acc, [id, data]) => {
          acc[id] = Object.fromEntries(
            Object.entries(data).filter(
              ([key]) => !FILTER_FROM_GSHEET.includes(key)
            )
          );
          return acc;
        },
        {}
      );

      localStorage.setItem(
        LocalStorageKeys.mlogData,
        JSON.stringify({
          updateTimestamp: updateTime.format(),
          data: filteredData,
          invalidSystemDate: invalidDate,
        })
      );
      this.setState(() => {
        if (!this._isMounted) {
          return;
        }
        return {
          sendInsights: true,
          dataUpdateMoment: updateTime,
          data: filteredData,
          invalidSystemDate: invalidDate,
          ...this.extractAllIssues(
            filteredData,
            this.props.systems,
            this.props.orgsData,
            this.props.usersData
          ),
        };
      });
    });
  };

  matchOrgsToUsers = (orgsData, usersData) => {
    return Object.entries(usersData).reduce(
      ({ sysMatch, orgMatch }, [phone, user]) => {
        const orgId = user.organization;
        if (!orgMatch[orgId]) {
          orgMatch[orgId] = { ...orgsData[orgId], users: [] };
        }
        if (ROLES_TO_SEND.includes(user.role)) {
          orgMatch[orgId].users.push(phone);
        }

        orgsData[orgId].systemIds.forEach((sysId) => {
          if (!sysMatch[sysId]) {
            sysMatch[sysId] = [];
          }
          if (!sysMatch[sysId].includes(orgId)) {
            sysMatch[sysId].push(orgId);
          }
        });
        return { sysMatch, orgMatch };
      },
      { sysMatch: {}, orgMatch: {} }
    );
  };

  extractAllIssues = (mlogData, systems, orgsData, usersData) => {
    const { sysMatch, orgMatch } = this.matchOrgsToUsers(orgsData, usersData);
    let invalidSystemConfiguration = false;
    const result = Object.entries(mlogData).reduce((issues, [id, data]) => {
      if (!systems[id]) {
        return issues;
      }

      const rawSystemIssues = extractIssues(
        data,
        systems[id].inverters,
        ISSUES_TO_FILTER
      );

      if (rawSystemIssues.length !== 0) {
        if (!sysMatch[id]) {
          console.warn(`problem with system: '${id}'`);
          invalidSystemConfiguration = true;
          sysMatch[id] = [];
        }
        // get label and phones and emails for a system
        const { phones, emails, label } = sysMatch[id].reduce(
          (acc, sysOrgId) => {
            orgMatch[sysOrgId].users.forEach((phone) => {
              const user = usersData[phone];
              const presetSms = ALERTS_TO_SEND.some((alertType) => {
                return user.alerts.toSend[alertType].methods.sms;
              });
              const presetEmail = ALERTS_TO_SEND.some((alertType) => {
                return user.alerts.toSend[alertType].methods.email;
              });
              if (presetSms || presetEmail) {
                if (presetSms && !acc.phones.includes(phone)) {
                  acc.phones.push(phone);
                }
                if (presetEmail && !acc.emails.includes(user.email)) {
                  acc.emails.push(user.email);
                }
                if (acc.label === null) {
                  acc.label = user.label[0];
                } else if (acc.label !== user.label[0]) {
                  acc.label = "";
                }
              }
            });
            return acc;
          },
          { phones: [], emails: [], label: null }
        );

        const systemIssues = rawSystemIssues
          .map((issue) => {
            return {
              name: systems[id].name,
              ...getCleanIssueState(
                id,
                issue,
                label || "",
                phones.join(LIST_SEPERATOR),
                emails.join(LIST_SEPERATOR)
              ),
            };
          })
          .filter((issue) => issue.severity > BASE_SEVERITY_THRESHOLD);
        issues.push(...systemIssues);
      }
      return issues;
    }, []);
    return { issues: result, invalidSystemConfiguration };
  };

  componentDidMount = () => {
    this._isMounted = true;
    // check if translator is ready, if not force update afterwards
    if ("then" in fixedTs.ready) {
      fixedTs.ready.then(() => this.forceUpdate());
    }
    const sysIds = [
      ...Object.keys(this.props.testSystems),
      ...Object.keys(this.props.systems),
    ];
    const localData = JSON.parse(
      localStorage.getItem(LocalStorageKeys.mlogData)
    );
    if (!validateLocalStorage(localData)) {
      this.reloadOperationalData(sysIds);
    } else {
      this.setState(() => {
        if (!this._isMounted) {
          return;
        }
        return {
          dataUpdateMoment: moment(localData.updateTimestamp),
          data: localData.data,
          invalidSystemDate: localData.invalidSystemDate,
          ...this.extractAllIssues(
            localData.data,
            this.props.systems,
            this.props.orgsData,
            this.props.usersData
          ),
        };
      });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  onUpdateNow = () => {
    const sysIds = [
      ...Object.keys(this.props.testSystems),
      ...Object.keys(this.props.systems),
    ];
    this.cleanLocalStorageData();
    this.reloadOperationalData(sysIds);
  };

  updateIssueState = (issueIdx, updateKeys, updateValues) => {
    this.setState((state) => {
      if (!this._isMounted || !state.issues) {
        return;
      }
      // allow multiupdates to avoid many consectutive state changes, ad hoc solution
      if (!Array.isArray(updateKeys)) {
        updateKeys = [updateKeys];
        updateValues = [updateValues];
      }
      const issues = [...state.issues];
      updateKeys.forEach(
        (key, idx) => (issues[issueIdx][key] = updateValues[idx])
      );
      // in case severity changes, don't allow send
      if (issues[issueIdx].severity <= BASE_SEVERITY_THRESHOLD) {
        issues[issueIdx].shouldSend = false;
      }
      return {
        issues,
      };
    });
  };

  // should be called from within a setState function, therefore, should not invoke state, or setState
  recalculateInsightsFromIssues = (insights, issues, systems) => {
    const issuesData = messageGenerator.getIssuesData();
    const newInsights = { ...insights };
    const changedSystems = {};
    const parentSystems = {};

    issues.forEach(({ id, status, issueKey, originalIssuesNames }) => {
      // iterate over all issues, and update insights data with new values
      (Array.isArray(originalIssuesNames)
        ? originalIssuesNames
        : [issueKey]
      ).forEach((issueName) => (newInsights[id][issueName] = status));

      // mark both changed systems and their parents to recalculate severities
      changedSystems[id] = true;
      if (systems[id].parent_id) {
        parentSystems[systems[id].parent_id] = true;
      }
    });

    // recalculate severities of changed systems
    Object.keys(changedSystems).forEach((id) => {
      newInsights[id].severity = Object.entries(newInsights[id]).reduce(
        (sysSeverity, [key, value]) => {
          // checks if name needs to be refactored
          const funcName = !key.startsWith("if-")
            ? key
            : key.replace(/\d+/, "n");

          if (issuesData[funcName] && issuesData[funcName][value]) {
            sysSeverity = `${Math.max(
              sysSeverity,
              issuesData[funcName][value].severity
            )}`;
          }
          return sysSeverity;
        },
        "0"
      );
    });

    // recalculate parent system's severities
    Object.keys(parentSystems).forEach((parentId) => {
      const childrenSeverities = systems[parentId].children_id.map((child) =>
        newInsights[child] ? newInsights[child].severity : "-1"
      );
      newInsights[parentId].severity = `${Math.max(...childrenSeverities)}`;
    });
    return newInsights;
  };

  onSendAll = () => {
    if (!window.confirm("Send all checked messages and push insights?")) {
      console.log("cancelled send all");
      return;
    }
    const lastUpdate = this.state.dataUpdateMoment;
    if (
      moment().diff(lastUpdate, "minutes") > MINUTES_BEFORE_STALE_DATA &&
      !window.confirm(`data is from ${lastUpdate.format()}`)
    ) {
      console.log("cancelled due to data staleness");
      return;
    }
    console.log("updating send queue");
    this.setState(
      (state, props) => {
        if (!this._isMounted) {
          return;
        }
        const insights = this.recalculateInsightsFromIssues(
          state.data,
          state.issues,
          props.systems
        );
        const sendQueue = [
          ...state.sendQueue,
          ...state.issues
            .filter((issue) => issue.shouldSend)
            .map((issue) => {
              const phones = issue.phonesString
                .split(LIST_SEPERATOR)
                .map((phone) => phone.trim());
              const emails = issue.emailsString
                .split(LIST_SEPERATOR)
                .map((email) => email.trim());
              return new MessageLog(
                issue.id,
                phones,
                emails,
                LOG_STATUS.pending,
                this.createMessageString(issue),
                issue.label,
                issue.msg
              );
            }),
        ];
        const issues = state.issues.map((issue) => {
          if (issue.shouldSend) {
            issue.sent = true;
          }
          issue.shouldSend = false;
          return issue;
        });
        return {
          sendQueue,
          issues,
          data: insights,
        };
      },
      () => this.onTransferInsightsToDB()
    );
  };

  componentDidUpdate = () => {
    if (
      this.state.sendPromiseIdx === null &&
      this.state.sendQueue.some((log) => log.status === LOG_STATUS.pending)
    ) {
      this.setState(
        (state) => {
          // don't move up, data must be fresh at this point
          const sendPromiseIdx = state.sendQueue.findIndex(
            (log) => log.status === LOG_STATUS.pending
          );
          state.sendQueue[sendPromiseIdx].status = LOG_STATUS.sending;
          return {
            sendPromiseIdx,
          };
        },
        () => this.sendMessage(this.state.sendQueue[this.state.sendPromiseIdx])
      );
    }
  };

  sendMessage = (messageLog) => {
    sms
      .sendSMS(
        messageLog.body,
        messageLog.recipientNumber,
        messageLog.sender,
        messageLog.type,
        DEFAULT_LNG,
        messageLog.sysId,
        null,
        "open",
        messageLog.emails,
        messageLog.body.body,
        messageLog.lastEdit.format("YYYY-MM-DD HH:mm")
      )
      .then((res) => {
        messageLog.status = LOG_STATUS.success;
        messageLog.messageId = res.map((awsRes) => awsRes.MessageId || "");
        messageLog.messageId.forEach((awsId, idx) => {
          if (!awsId) {
            messageLog.singlePhoneError[idx] = new Error("problem sending");
            messageLog.singlePhoneStatus[idx] = LOG_STATUS.failure;
          } else {
            messageLog.singlePhoneError[idx] = null;
            messageLog.singlePhoneStatus[idx] = LOG_STATUS.success;
          }
        });
      })
      .catch((err) => {
        console.error(err);
        messageLog.status = LOG_STATUS.failure;
        messageLog.error = err;
      })
      .finally(() => {
        if (!this._isMounted) {
          return;
        }
        messageLog.lastEdit = moment();
        this.setState({
          sendPromiseIdx: null,
        });
      });
  };

  // returns true only if every issue is checked for sending
  shouldSendAll = () => {
    return this.state.issues.every(
      (issue) => issue.shouldSend || issue.severity <= BASE_SEVERITY_THRESHOLD
    );
  };

  // if every issue is checked for send cancells them, checks them otherwise
  flipSendAll = () => {
    this.setState((state) => {
      if (!this._isMounted) {
        return;
      }
      const changeTo = !this.shouldSendAll();
      const issues = state.issues.map((iss) => {
        if (iss.severity > BASE_SEVERITY_THRESHOLD) {
          iss.shouldSend = changeTo;
        }
        return iss;
      });
      return {
        issues,
      };
    });
  };

  createMessageString = (issue) => {
    const t = fixedTs[issue.lng];
    const extraMessage =
      issue.severity >= ALERT_SEVERITY_THRESHOLD
        ? "alert"
        : issue.severity >= WARNING_SEVERITY_THRESHOLD
        ? "warning"
        : "message";
    const message =
      `${t("system")} ${issue.name}: ${t(extraMessage)} - ` +
      t(issue.msg, issue.params);
    return {
      body: message,
      params: {
        version: TRANSLATOR_VERSION,
        msg: issue.msg,
        params: issue.params,
      },
    };
  };

  // transfers data from gsheet to DB, needs to be done when backend functionality is done
  onTransferInsightsToDB = () => {
    if (!this.state.sendInsights) {
      return;
    }
    const urlPost = "/api/insights/daily";
    const urlPut = "/api/insight/daily";
    const existingRowStatusCode = 403;

    this.setState(
      { isPushingInsights: true, insightsPushStatus: "pushing..." },
      () => {
        if (!this._isMounted) {
          return;
        }
        let dbPayload;
        const systems = { ...this.props.systems, ...this.props.testSystems };
        try {
          dbPayload = OperationalTabModel(this.state.data, systems);
        } catch (err) {
          console.error(err);
          this.setState({
            isPushingInsights: false,
            insightsPushStatus: err.message,
          });
          return;
        }
        const token = localStorage.getItem(LocalStorageKeys.userToken);
        Promise.all(
          Object.values(dbPayload).map((sysPayload) =>
            sendData(sysPayload, urlPost, token, "post")
          )
        )
          .then((responses) => {
            return Promise.all(
              responses.map((res) => {
                if (res.status === existingRowStatusCode) {
                  const payload = dbPayload[res.data.systemId];
                  const url = `${urlPut}/${res.data.id}`;
                  return sendData(payload, url, token, "put").then((res) => {
                    res.method = "put";
                    return res;
                  });
                }
                res.method = "post";
                return res;
              })
            );
          })
          .then((responses) => {
            console.log(
              "done update",
              responses.map((res) => ({
                method: res.method,
                id: res.data.systemId,
                status: res.status,
                data: res.data,
              }))
            );
            const responseProblems = responses.filter((res) => !res.ok);
            if (responseProblems.length !== 0) {
              console.warn("problem with update of", responseProblems);
              throw new Error("error pushing insights to DB");
            }
            this.setState({
              isPushingInsights: false,
              insightsPushStatus: "success",
              sendInsights: false,
            });
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              isPushingInsights: false,
              insightsPushStatus: "failure, press [F12] for more details",
              sendInsights: false,
            });
          });
      }
    );
  };

  changeSendInsights = () => {
    this.setState((state) => {
      return {
        sendInsights: !state.sendInsights,
      };
    });
  };

  render() {
    const {
      data,
      dataUpdateMoment,
      issues,
      isPushingInsights,
      insightsPushStatus,
      invalidSystemDate,
      invalidSystemConfiguration,
    } = this.state;
    if (!data || !dataUpdateMoment || !issues || "then" in fixedTs.ready) {
      return <div>Loading messages data</div>;
    }
    return (
      <div>
        <Section>
          <h3>Messages log</h3>
          <span>last updated: {dataUpdateMoment.format("HH:mm")}</span>
          {!invalidSystemDate ? null : (
            <div style={{ color: "red" }}>error with system date</div>
          )}
          {!invalidSystemConfiguration ? null : (
            <div style={{ color: "red" }}>error with system configuration</div>
          )}
        </Section>
        <Section>
          <button onClick={this.onUpdateNow} disabled={isPushingInsights}>
            update now
          </button>
        </Section>
        {!issues.length ? (
          <Section>No severe issues</Section>
        ) : (
          <Section>
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={this.shouldSendAll()}
                      onChange={this.flipSendAll}
                    />
                  </th>
                  <th>system-id</th>
                  <th>message</th>
                  <th>&nbsp;</th>
                  <th>label</th>
                  <th>status</th>
                  <th>phones</th>
                  <th>emails</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {issues.map((issue, idx) => {
                  return (
                    <IssueItem
                      key={idx}
                      thresholdSeverity={BASE_SEVERITY_THRESHOLD}
                      issueData={ISSUES_DATA[issue.issueKey]}
                      idx={idx}
                      messageString={this.createMessageString(issue).body}
                      updateIssue={this.updateIssueState}
                      {...issue}
                    />
                  );
                })}
              </tbody>
            </table>
          </Section>
        )}
        <Section>
          <button
            onClick={this.onSendAll}
            disabled={this.state.isPushingInsights}
          >
            Send all
          </button>
          <span style={{ marginLeft: "20em", marginRight: "1em" }}>
            <input
              type="checkbox"
              checked={this.state.sendInsights}
              onChange={this.changeSendInsights}
            />
            push insights: {insightsPushStatus}
          </span>
        </Section>
        <Section>
          <h4>Messages queue</h4>
          <ul>
            {this.state.sendQueue.map((log) => {
              return log.recipientNumber.map((number, idx) => {
                const singleLog = { ...log };
                singleLog.recipientNumber = number;
                singleLog.messageId = log.messageId[idx];
                singleLog.error = log.singlePhoneError[idx];
                singleLog.status = log.singlePhoneStatus[idx];
                return <MessageLogItem key={idx} log={singleLog} />;
              });
            })}
          </ul>
        </Section>
      </div>
    );
  }
}

export default AutoMessagesForm;
