import styled from "styled-components";

export const HIGHLIGHT_BACKGROUND_COLOR = "#d3d3d3";
export const NTH_BG_COLOR = "#006060";
export const DEFAULT_NUMBER_OF_LINES_IN_TD = 2;

export const HoverableRow = styled.tr`
  &:nth-of-type(even) {
    background: ${NTH_BG_COLOR};
    input,
    select,
    button {
      background: ${NTH_BG_COLOR};
    }
  }
  &:hover {
    color: blue;
    background: ${HIGHLIGHT_BACKGROUND_COLOR};
    cursor: default;
  }
`;

export const HoverableTd = styled.td`
  color: unset;
  background: unset;
  ${(props) => {
    if (props.shouldClampLines) {
      return `
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${DEFAULT_NUMBER_OF_LINES_IN_TD};
        overflow: hidden;
      `;
    }
    return "";
  }};
`;

export const HoverableBorderTd = styled.td`
  color: unset;
  background: unset;
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;
