import React from "react";

import AdminMessagesForm from "../components/MessagesAdmin";
import { sendData } from "../services/sendData";
import localStorageKeys from "../localStorageKeys";

class MessagesAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // fetch label data
    sendData(
      null,
      "/api/labels",
      localStorage.getItem(localStorageKeys.userToken),
      "GET"
    ).then((res) => this.setState({ labels: res.data }));
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const {
      noRefSystems,
      testSystems,
      frozenSystems,
      usersData,
      sysMatch,
      orgsData,
    } = this.props;
    const systems = {
      "": { id: "", name: "" },
      ...(testSystems || {}),
      ...(noRefSystems || {}),
      ...(frozenSystems || {}),
    };
    if (Object.keys(systems).length <= 1 || !this.state.labels) {
      return <div>Loading systems...</div>;
    }

    return (
      <AdminMessagesForm
        systems={systems}
        sysMatch={sysMatch}
        usersData={usersData}
        orgsData={orgsData}
        labels={this.state.labels}
      />
    );
  }
}

export default MessagesAdmin;
